<div class='container-fluid'>
  <div class='row'>
    <div class='col'>
      <h1>{{title}}</h1></div>
  </div>
  <div class='row'>
    <div class='col'>
      <form [formGroup]='form' (ngSubmit)='addProductToOrder()'>
        <div class='row'>
          <div class='col-auto'>
            <div class='row'>
              <div class='col-auto'>
                <mat-checkbox
                  formControlName='check1'
                  color='primary'
                  value='1'
                ></mat-checkbox>
              </div>
              <div class='col-auto'>
                <mat-form-field appearance='outline'>
                  <mat-label> Доставку по Харькову</mat-label>
                  <input matInput readonly formControlName='delivery' />
                </mat-form-field>
              </div>
            </div>
            <div class='row'>
              <div class='col-auto'>
                <mat-checkbox
                  formControlName='check2'
                  color='primary'
                  value='1'
                ></mat-checkbox>
              </div>
              <div class='col-auto'>
                <mat-form-field appearance='outline'>
                  <mat-label> Транспортные расходы</mat-label>
                  <input matInput formControlName='send' />
                </mat-form-field>
              </div>
            </div>
            <div class='row'>
              <div class='col-auto'>
                <mat-checkbox
                  formControlName='check3'
                  color='primary'
                  value='1'
                ></mat-checkbox>
              </div>
              <div class='col-auto'>
                <mat-form-field appearance='outline'>
                  <mat-label> Дащита ДВС (цена)</mat-label>
                  <input matInput formControlName='dvs' />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class='col-auto'>
            <mat-form-field
              appearance='outline'
              *ngIf='orderList$ | async as orderList'
            >
              <mat-label>Список заказов</mat-label>
              <mat-select formControlName='orderList'>
                <mat-option *ngFor='let item of orderList' [value]='item.value'>{{
                  item.text
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col-auto'>
            <button type='submit' mat-button class='btn btn-success'>
              Добавить в заказ
            </button>
          </div>
        </div>
      </form>
      <mat-divider></mat-divider>
      <div class='row'>
        <div class='col'>
          <mat-form-field appearance='outline'>
            <mat-label>Поиск</mat-label>
            <input matInput [formControl]='searchTree'>
          </mat-form-field>
        </div>
        <div class='col'>
          <mat-form-field appearance='outline' class='w-100' *ngIf='clientCriList$|async as clientCriList'>
            <mat-label>Категория клиента</mat-label>
            <mat-select [formControl]='clientCri' (selectionChange)='loadDopSaleTreeList()'>
              <lib-select-with-filter
                [list]='clientCriList'
                #clientCriListFilter
              ></lib-select-with-filter>
              <mat-option>---</mat-option>
              <mat-option
                style='font-size: 16px !important'
                *ngFor='let item of clientCriListFilter.list'
                [value]='item.value'
              >
                {{ item.data }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>


      <div class='row' *ngIf='loadFastProduct$ | async'>


        <div
          class='col-3'
          *ngFor='let item of productTreeList'
        >
          <p class='bold'>{{ item.name }}</p>
          <div class='row cursor-pointer hover align-items-center' (click)='loadDataInfoProd(val)'
               *ngFor='let val of item.tree'>
            <div class='col-2 p-0'>
              <img [src]='val.photo' alt='' class='w-100' style='max-height: 100px'>
            </div>
            <div class='col-8'>
              <p>{{val.name}}</p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>

